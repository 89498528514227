<template>
  <div>
    <div class="main-p main-p8" v-show="true">
      <p class="tit hidden-sm-and-down">Change Password</p>
      <p class="m-tit hidden-md-and-up">Please fill out the following information: </p>
      <div class="p6-form">
        <el-form :model="changepasslist" status-icon :rules="P8rules" ref="p8ruleForm" label-width="2.8777rem" label-position="left">
          <el-form-item label="Please enter the current password" prop="oldpass"><el-input type="password" v-model="changepasslist.oldpass"></el-input></el-form-item>
          <el-form-item label="Please enter a new password" prop="newpass"><el-input type="password" v-model="changepasslist.newpass"></el-input></el-form-item>
          <el-form-item label="Please enter the new password again" prop="checknewpass"><el-input type="password" v-model="changepasslist.checknewpass"></el-input></el-form-item>
          <el-form-item class="is-required" label="Authentication">
            <drag-verify
              :width="isphone"
              :height="44"
              handlerIcon="el-icon-d-arrow-right"
              successIcon="el-icon-check"
              text="Drag the slider to the right"
              success-text="Successful"
              background="#ddd"
              progress-bar-bg="#2ACBF8"
              text-size="0.3rem"
              :circle="false"
              @passcallback="p8passcall"
            ></drag-verify>
          </el-form-item>
        </el-form>
        <div class="form-sub"><div class="form-sub-btn" @click="p8submit()">Submit</div></div>
      </div>
    </div>
  </div>
</template>
<script>
import dragVerify from 'vue-drag-verify';
import { editpw, editsave } from '../../api';
import qs from 'qs';
export default {
  name: 'cityG8',
  data() {
    const _this = this;
    var checknewpass = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (_this.changepasslist.newpass == _this.changepasslist.oldpass) {
        callback(new Error('The new password should not be same with the previous password. '))
      } else {
        if (_this.changepasslist.newpass.match(regx) == null) {
          callback(new Error('Your password needs to be a combination of numbers and letters'));
        } else {
          callback();
        }
      }
    };
    var checknewpass2 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (_this.changepasslist.newpass != _this.changepasslist.checknewpass) {
        callback(new Error('Please enter the new password again'));
      } else {
        if (_this.changepasslist.newpass.match(regx) == null) {
          callback(new Error('Your password needs to be a combination of numbers and letters'))
        } else {
          callback()
        }
      }
    }
    return {
      isphone: 0,
      changepasslist: {
        oldpass: '',
        newpass: '',
        checknewpass: '',
        P8text: false
      },
      P8rules: {
        oldpass: [{ required: true, message: 'Please enter the previous password', trigger: 'blur' }, { min: 6, max: 24, message: 'Password at least 6 digits' }],
        newpass: [{ required: true, validator: checknewpass, trigger: 'blur' }, { min: 6, max: 24, message: 'Your password must be a combination of 6 to 24 numbers and letters' }],
        checknewpass: [{ required: true, validator: checknewpass2, trigger: 'blur' }, { min: 6, max: 24, message: 'Your password must be a combination of 6 to 24 numbers and letters' }]
      }
    }
  },
  mounted () {
    // this.isphone = parseInt($('.main-p8 .p6-form').width())
    if (/iphone|ipad|nokia|sony|ericsson|mot|samsung|sgh|lg|philips|panasonic|alcatel|lenovo|cldc|midp|wap|android|iPod/i.test(navigator.userAgent.toLowerCase())) {
      if ($('.main-p8 .p6-form').width() == 0) {
        this.$nextTick(() => {
          this.isphone = parseInt($('.main-p8 .m-tit')[7].clientWidth - 1)
        })
      } else {
        this.isphone = parseInt($('.main-p8 .p6-form').width())
      }
    } else {
      this.isphone = 300
    }
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        if (sc.isSZSMT()) {
          console.log('在i深圳平台')
          this.$parent.linkIshenzhen()
        } else {
          console.log('不在i深圳平台内')
          this.$router.push('/LoginEN')
        }
      })
    }
  },
  methods: {
    p8submit () {
      this.$refs.p8ruleForm.validate(valid => {
        if (valid) {
          if (!this.changepasslist.P8text) {
            this.$message({
              showClose: true,
              message: 'Please finish security verification',
              type: 'error',
              center: true
            });
            return;
          } else {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let un = userInfo.userName
            let upw = this.getmd5(this.changepasslist.oldpass);
            editpw(un, upw).then(res => {
              if (res.data) {
                console.log(this.changepasslist.newpass)
                let list = {
                  SPONSOR_ID: userInfo.userId,
                  PASSWORD: this.getmd5(this.changepasslist.newpass)
                };
                editsave(qs.stringify(list)).then(res => {
                  if (res.data == 'ok') {
                    this.$message({
                      showClose: true,
                      message: 'Password changed successfully',
                      type: 'success',
                      center: true
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  } else {
                    this.$message({
                      showClose: true,
                      message: 'Password change failed. Please try again',
                      type: 'error',
                      center: true
                    });
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: 'Incorrect previous password',
                  type: 'error',
                  center: true
                });
              }
            });
          }
        }
      })
    },
    p8passcall() {
      this.changepasslist.P8text = true;
    },
    getmd5(code) {
      return this.$md5(code);
    }
  },
  components: { dragVerify }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityGe.styl">

</style>
<style scoped lang="stylus">
@media screen and (max-width: 990px){
  .G-container.en {
    .main-p{
      &.main-p8{
        .m-tit{
          color #8d8d8d
          font-size 0.3703rem
          padding .4rem 0
          border-bottom 0.0185rem solid #e2e2e2
        }
        .p6-form{
          .form-sub{
            padding .4rem 0 .4rem 0
            .form-sub-btn{
              display block
              font-size 0.3703rem
              padding .2rem 0
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
  .main-p&.main-p8 .el-form-item__label{
    display flex
    line-height 1.4
  }
@media screen and (max-width: 990px){
  .G-container.en {
    .content-box{
      .content{
        .main-p{
          &.main-p8{
            .p6-form{
              .el-input{
                width 100%
              }
              .el-form-item__label{
                width 100% !important
                float initial
                line-height 40px
              }
              .el-form-item__content{
                width 100%
                margin-left 0 !important
              }
              .drag_verify{

              }
            }
          }
        }
      }
    }
  }
}
</style>
